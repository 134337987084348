<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>트래픽 현황</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">MY</a></li>
                        <li><a href="#">서비스 상태</a></li>
                        <li><span>트래픽 현황</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section trafficPrstus">
            <div class="innWrap">
                <div class="lineBox">
                    <div class="form-list">
                        <div class="form-Item">
                            <div class="fcon multi">
                                <!--div class="select w288px" id="traffic-title-01">
                                    <span class="placeholder"><span class="fcGy">전용회선번호</span></span>
                                    <div class="list">
                                        <ul>
                                            <li v-for="item in trafficInfo" :key="item.displayseq" :value="item.code_cd" @click="selectParamType(item.code_cd)">{{item.code_nm}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <input type="text" id="traffic-title-02" placeholder="조회조건을 선택해주세요" v-model="paramValue"-->
                                <div class="select" id="traffic-title-01" style="margin-right: 0px;">
                                    <span class="placeholder">
                                        <span class="fcGy">상품명 / 전용회선번호 / 설치주소</span>
                                    </span>
                                    <div class="list">
                                        <ul>
                                            <li v-for="item in userSvcInfo" :key="item.scn" :value="item.scn" @click="selectValue(item.svctypename ,item.llnum)">{{item.svctypename + (item.llnum == '' ? ' / - / ' : ' / '+ item.llnum + ' / ') + item.addr + item.addrnum + item.addrsubnum + item.detailaddr}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-Item02">
                            <div class="fcon w736px">
                                <label for="traffic-title-03">IP 주소</label>
                                <input type="text" id="traffic-title-03" placeholder="IP주소를 입력해주세요 ex) 127.0.0.1" v-model="ipValue"  v-bind:disabled="!ipCheck">
                            </div>
                            <div class="fcon inquiry">
                                <label for="startDate">조회기간 <span class="fcPink fw600">(1일치)</span></label>
                                <div class="calendar">
																			<date-picker v-model="startDt"
																					:lang="lang"
																					format="YYYY-MM-DD"
																					:disabled-date="disabledStartDate"
																					valueType="YYYY-MM-DD" type="date"></date-picker>
                                    <!-- <input type="text" id="startDate" autocomplete="off" placeholder="2022/07/12 00:00" class="input-line input-calendar">
                                    <em>~</em>
                                    <input type="text" id="endDate" autocomplete="off" placeholder="2022/07/13 24:00" class="input-line input-calendar"> -->
                                    <a @click="getTroubTicketInfo()" class="btn-md02 btn-primary">조회</a>
                                </div>
                            </div>
                        </div>
                    </div><!-- //form-list -->
                    <div class="trfGraphWrap">
                        <p class="tit">회선  total  트래픽</p>
                        <div class="trfTotal">
                            <div class="trftable" style="width: 430px;">
                                <div class="trfInfo">
                                    <dl>
                                        <dt>서비스명</dt>
                                        <dd>{{name}}</dd>
                                    </dl>
                                    <dl>
                                        <dt>회선번호<br />(전용or인터넷)</dt>
                                        <dd>{{paramValue}}</dd>
                                    </dl>
                                    <dl>
                                        <dt>In실시간<br />(5분전) Mbps</dt>
                                        <dd>{{tableData.inbps}} Mbps</dd>
                                    </dl>
                                    <dl>
                                        <dt>Out실시간<br />(5분전) Mbps</dt>
                                        <dd>{{tableData.outbps}} Mbps</dd>
                                    </dl>
                                </div>
                                <p class="asterisk_red mgt20">※ 상세 트래픽 정보는 관제포탈 접속 후 확인이 가능합니다.</p>
                            </div>
                            <div class="trfGraph">
                                <!-- img src="@/assets/images/graph.png" alt="트래픽그래프" -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import * as d3 from 'd3'

export default {
  components: {
    HeaderLayout,
    FooterLayout,
		DatePicker
  },
  data () {
    return {
			startDt: '',
			todayDt: '',
			lang: {
				days: ["일", "월", "화", "수", "목", "금", "토"],
				months: [
					"1월",
					"2월",
					"3월",
					"4월",
					"5월",
					"6월",
					"7월",
					"8월",
					"9월",
					"10월",
					"11월",
					"12월",
				],
					yearFormat: "YYYY년",
					monthFormat: "MM월",
					monthBeforeYear: false,
			},
			paramType: '1',
			paramValue: '',
            ipValue: '',
            chartsData: [],
            tableData: { 
                "inbps" : 0
                , "outbps" : 0
            },
            trafficInfo: '',
            userSvcInfo: '',
            name: '',
            ipCheck: true
    }
  },
  mounted: async function () {
		this.startDt = moment().subtract("0", "days").format("YYYY-MM-DD")
		this.todayDt = moment().subtract("0", "days").format("YYYY-MM-DD")
        await this.getTrafficCodeList()
        await this.getOwnerContactInfoByTokenId()
  },
  computed: {
    isValidIp() {
      const ipPattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return this.ipValue.match(ipPattern) !== null;
    }
  },
  methods: {
		disabledStartDate(date) {
			return (moment(date).format("YYYY-MM-DD") >= moment().add("1", "days").startOf("day").format("YYYY-MM-DD"))
		},
        async getOwnerContactInfoByTokenId() {
            let token_id = sessionStorage.getItem("accessToken");
            let param = { token_id: token_id };
            this.$store
                .dispatch("auth/getOwnerContactInfoByTokenId", param)
                .then(res => {
                // console.log(res.partyIdNumber1)
                let ownerContactInfo = res;
                let partyIdNumber;
                //개인
                // if (sessionStorage.getItem("partyTypeId") === "01") {
                //     partyIdNumber = ownerContactInfo.partyIdNumber1;
                // } else {
                //     partyIdNumber = ownerContactInfo.partyIdNumber2;
                // }
                setTimeout(() => {
                    // this.getUserSvcInfo(partyIdNumber);
                    this.getUserSvcInfo(ownerContactInfo.partyIdNumber1, ownerContactInfo.partyIdNumber2, ownerContactInfo.encPartyIdNumber1 ,ownerContactInfo.encPartyIdNumber2);
                }, 200);
            })
                .catch(error => {
                console.error(error);
            });
        },
        async getUserSvcInfo(custNum1, custNum2, encCustNum1, encCustNum2) {
            try {
                let custType = "" ,param = {} ,temp = [] ,key = 'scn';
                custNum1.length === 13 ? (custType = 3) : (custType = 8);
                // TODO TEST
                param = {
                    custType: custType,
                    custNum: encCustNum1
                };
                const res1 = await this.$store.dispatch("gigaoffice/getUserSvcInfo", param);
                
                custNum2.length === 13 ? (custType = 3) : (custType = 8);
                // TODO TEST
                param = {
                    custType: custType,
                    custNum: encCustNum2
                };
                const res2 = await this.$store.dispatch("gigaoffice/getUserSvcInfo", param);

                if(res1 !== undefined && res1.length > 0){
                    res1.forEach(function(value ,index){
                    temp.push(value)
                    })
                }
                if(res2 !== undefined && res2.length > 0){
                    res2.forEach(function(value ,index){
                    temp.push(value)
                    })
                }
                temp = temp.reduce((i ,item) => {
                    const removed = i.filter(j => j[key] !== item[key])
                    return [...removed ,item]
                }, [])
                this.userSvcInfo = temp
                // if (res.length > 0) {
                //   this.userSvcInfo = res;
                // }
            } catch (err) {
                console.log(err);
            }
            // let custType = "";
            // custNum.length === 13 ? (custType = 3) : (custType = 8);
            // // TODO TEST
            // const param = {
            //     custType: custType,
            //     custNum: custNum
            // };
            // // let param = {custType : custType, custNum : '7102271V00541'}
            // try {
            //     const res = await this.$store.dispatch(
            //         "gigaoffice/getUserSvcInfo",
            //         param
            //     );
            //     if (res.length > 0) {
            //         this.userSvcInfo = res;
            //         // console.log(res);
            //     }
            // } catch (err) {
            //     console.log(err);
            // }
        },
        selectValue(name, paramValue) {
            if(name.toLowerCase().includes('basic')){
                this.ipCheck = false;
            }else{
                this.ipCheck = true;
            }
            this.paramValue = paramValue;
            this.name = name;
        },
		async getTroubTicketInfo() {
			// if(moment(this.startDt).diff(moment(this.endDt)) <= -604800000) {
			// 	alert('조회 기간이 7일을 초과했습니다.')
			// 	return false
			// }
            this.ipValue = this.ipValue.replace(/ /g ,"")
            if (this.ipValue != '' && !this.isValidIp) {
                alert('입력하신 IP주소를 확인해주세요.')
				return false
            }

            this.ipCheck ? this.paramType = 1 : this.paramType = 2
            let ipTemp = this.ipCheck ? this.ipValue : ''
            // this.paramValue = '027005019038'
			let payload = {
                // llnum basic - 2 ip 입력창 막음
				paramType: this.paramType,	// 1:전용회선번호, 2:접속번호, 3:서비스번호
				paramValue: this.paramValue, // 검색유형에 해당하는 값 027005019038
				stDay: this.startDt, //yyyy-mm-dd
				edDay: this.startDt, //yyyy-mm-dd
				tdDay: this.todayDt, //yyyy-mm-dd
				ip: ipTemp
			}
            // console.log('payload : ', payload)
			await this.$store.dispatch('gigaoffice/getTroubTicketInfo', payload).then(res => {
                this.tableData = res.data.response.table
                this.chartsData = res.data.response.charts
                
                if(document.querySelector('.trfGraph svg') != null ){
                    document.querySelector('.trfGraph svg').remove()
                }
                if(document.querySelector('.trfGraph .tooltip') != null ){
                    document.querySelector('.trfGraph .tooltip').remove()
                }
                if(this.chartsData.length > 0){
                    this.charts()
                }
			})
			.catch(err => {
				console.log(err)
			})
		},
        async getTrafficCodeList() {
			const res = await this.getComCodeList('TC')
			this.trafficInfo = res.data.response.results
		},
        async getComCodeList(group_cd, code_cd) {
			let payload = {
				group_cd : group_cd,
				code_cd : code_cd 
			}
			let response = {}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				response = res
			})
			.catch(err => {
				console.log(err)
			})
			return response
		},
        // selectParamType(code_cd) {
        //     this.paramType = code_cd
        // },
        charts() {
            const groupData = this.chartsData;
            let margin = {top: 20, right: 20, bottom: 30, left: 40},
            width = 900 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;
            
            let x0 = d3.scaleBand()
            .rangeRound([0, width], .5)
            .paddingInner(0.1);

            let x1 = d3.scaleBand();
            let y = d3.scaleLinear().rangeRound([height, 0]);

            let xAxis = d3.axisBottom().scale(x0)
            .tickValues(groupData.map(d=>d.key));

            let yAxis = d3.axisLeft().scale(y);

            const color = d3.scaleOrdinal(d3.schemeCategory10);

            let svg = d3.select('.trfGraph').append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .attr('style', 'position: relative; left: 50px;')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

            let tools = groupData.map(function(d) { return d.key; });
            let dates = groupData[0].values.map(function(d) { return d.grpName; });

            // let maxValue = 0;
            // groupData.forEach(function(value ,index){
            //     value.values.forEach(function(cValue ,cIndex){
            //         if(cValue.grpValue > maxValue){
            //             maxValue = cValue.grpValue
            //         }
            //     })
            // })

            // maxValue = Number(maxValue)+Number(1)
            // console.log(maxValue)

            x0.domain(tools);
            x1.domain(dates).rangeRound([0, x0.bandwidth()]);
            y.domain([0, d3.max(groupData, function(key) { return d3.max(key.values, function(d) { return Number(d.grpValue)+Number(1); }); })]);
            // y.domain([0, maxValue]);

            svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(0,' + height + ')')
            .call(xAxis);

            svg.append('g')
            .attr('class', 'y axis')
            .style('opacity','0')
            .call(yAxis)
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '.71em')
            .style('text-anchor', 'end')
            .style('font-weight','bold')
            .text('Value');

            svg.select('.y').transition().duration(500).delay(1300).style('opacity','1');

            let slice = svg.selectAll('.slice')
            .data(groupData)
            .enter().append('g')
            .attr('class', 'g')
            .attr('transform',function(d) { return 'translate(' + x0(d.key) + ',0)'; })
            .attr('dis',function(d) { return x0(d.key); });

            let div = d3.select('.trfGraph').append('div')
                .attr('class' ,'tooltip')
                .style('opacity' ,0);

            slice.selectAll('rect')
            .data(function(d) { return d.values; })
            .enter().append('rect')
            .attr('width', x1.bandwidth())
            .attr('x', function(d) { return x1(d.grpName); })
            .style('fill', function(d) { return color(d.grpName) })
            .attr('y', function(d) { return y(0); })
            .attr('height', function(d) { return height - y(0); })
            .on('mouseover', function(d) {
                let wTemp = d3.select(this)._groups[0][0].parentNode;
                let dis1 = wTemp.getAttribute('dis');
                let dis2 = d3.select(this)._groups[0][0].getAttribute('width');
                let height = d3.select(this)._groups[0][0].height.animVal.value;
                let temp = d3.select(this)._groups[0][0].__data__.grpValue;
                div.transition()
                .duration(200)
                .style('opacity' ,9);
                div.html(temp + ' Mbps')
                .style('left', (parseInt(dis1) + parseInt(dis2) + 60) + 'px')
                .style('top', (-1 * (parseInt(height) + 70) ) + 'px');
            })
            .on('mouseout', function(d) {
                div.transition()
                .duration(500)
                .style('opacity' ,9);
            });

            slice.selectAll('rect')
            .transition()
            .delay(function (d) {return Math.random()*1000;})
            .duration(1000)
            .attr('y', function(d) { return y(d.grpValue); })
            .attr('height', function(d) { return height - y(d.grpValue); });

            //Legend
            let legend = svg.selectAll('.legend')
            .data(groupData[0].values.map(function(d) { return d.grpName; }).reverse())
            .enter().append('g')
            .attr('class', 'legend')
            .attr('transform', function(d,i) { return 'translate(0,' + i * 20 + ')'; })
            .style('opacity','0');

            legend.append('rect')
            .attr('x', width - 18)
            .attr('width', 18)
            .attr('height', 18)
            .style('fill', function(d) { return color(d); });

            legend.append('text')
            .attr('x', width - 24)
            .attr('y', 9)
            .attr('dy', '.35em')
            .style('text-anchor', 'end')
            .text(function(d) {return d; });

            legend.transition().duration(500).delay(function(d,i){ return 1300 + 100 * i; }).style('opacity','1');
        }
  }
}
</script>
<style>
    div.tooltip {
        position: relative;
        text-align: center;
        width: 70px;
        height: 28px;
        padding: 2px;
        font-size: 12px;
    }
</style>